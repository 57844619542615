<template>
  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <div class="d-flex align-items-center justify-content-start">
              <span class="text-justify">
                By embedding the Form on the Landing Page, you can have lead submissions from the visitors. You can not edit a form once it has been submited by a visitor.
              </span>
            </div>
            <!--            <label>Show</label>-->
            <!--            <v-select-->
            <!--              v-model="perPage"-->
            <!--              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
            <!--              :options="perPageOptions"-->
            <!--              :clearable="false"-->
            <!--              class="per-page-selector d-inline-block mx-50"-->
            <!--            />-->
            <!--            <label>entries</label>-->
          </b-col>

          <!-- Search -->
          <b-col
            class="offset-lg-1"
            cols="12"
            md="6"
            lg="5"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                variant="primary"
                :to="{ name: 'form-managers-create' }"
                class="d-flex"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span class="text-nowrap">Create Form</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <!-- basic modal -->
      <b-modal
        id="modal-preview"
        centered
        title="Form Preview"
        hide-footer
      >
        <form-field
          :hidden-submit-button="true"
          :form-id="parseInt(formData.id)"
        />
      </b-modal>
      <div class="d-flex justify-content-center">
        <b-spinner
          v-show="totalForms === null"
          label="Loading..."
          :variant="'primary'"
        />
      </div>
      <b-table
        ref="refFormListTable"
        class="position-relative"
        :items="getForms"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Title -->
        <template #cell(title)="data">
          <b-media vertical-align="center">
            <b-link :to="{ name: 'form-managers-edit', params: { id: data.item.id } }">
              {{ data.item.title }}
            </b-link>
          </b-media>
        </template>
        <template #cell(creator)="data">
          {{ data.item.creator.name }}
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="d-flex flex-row justify-content-start">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-modal.modal-preview
              v-b-tooltip.hover.top="'Preview'"
              variant="outline-primary"
              class="btn-icon rounded-circle mx-1"
              @click="fetchDetail(data.item.id)"
            >
              <feather-icon icon="FileTextIcon" />
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-tooltip.hover.top="'Delete'"
              variant="danger"
              class="btn-icon rounded-circle"
              @click="deleteForm(data.item.id)"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </div>
        </template>

      </b-table>
      <div
        v-if="dataMeta.of > perPage"
        class="mx-2 mb-2"
      >
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalForms"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
// import Vue from 'vue'
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink, VBModal,
  BPagination, BSpinner, BModal, BMedia, VBTooltip,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import FormField from '@/views/menus/forms/form-display/FormField.vue'
import useFormsList from './useFormsList'
import formStoreModule from '../formStoreModule'

export default {
  components: {
    FormField,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BSpinner,
    BLink,
    BModal,
    BMedia,
  },
  setup() {
    const FORM_APP_STORE_MODULE_NAME = 'menu-form'

    // Register module
    if (!store.hasModule(FORM_APP_STORE_MODULE_NAME)) store.registerModule(FORM_APP_STORE_MODULE_NAME, formStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FORM_APP_STORE_MODULE_NAME)) store.unregisterModule(FORM_APP_STORE_MODULE_NAME)
    })

    const {
      getForms,
      tableColumns,
      perPage,
      currentPage,
      totalForms,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refFormListTable,
      refetchData,
    } = useFormsList()

    return {
      getForms,
      tableColumns,
      perPage,
      currentPage,
      totalForms,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refFormListTable,
      refetchData,

      // Filter
      avatarText,
    }
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      formData: {
        id: null,
        slug: null,
        title: null,
        labels: [],
        types: [],
        required: [],
        options: [],
        aggreement: '',
      },
    }
  },
  methods: {
    fetchDetail(id){
      this.formData.id = id
    },
    deleteForm(formId) {
      this.$swal({
        title: 'Are you sure to delete ?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('menu-form/removeForm', { formId })
            .then(response => {
              this.$swal({
                icon: 'success',
                title: 'Deleted!',
                text: response.data.message,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.refetchData()
            })
            .catch(error => {
              if (error.response !== undefined){
                this.$swal({
                  icon: 'error',
                  title: 'Delete action failed!',
                  text: error.response.data.message,
                })
              }
            })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
