import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { formatDate } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useFormsList() {
  // Use toast
  const toast = useToast()

  const refFormListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'title', sortable: true },
    { key: 'creator', sortable: true },
    {
      key: 'created_at',
      label: 'Created At',
      formatter: formatDate,
      sortable: true,
    },
    { key: 'actions' },
  ]
  const perPage = ref(25)
  const totalForms = ref(null)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refFormListTable.value ? refFormListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalForms.value,
    }
  })

  const refetchData = () => {
    refFormListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
    refetchData()
  })

  const getFormsWithReadWuthorization = (ctx, callback) => {
    store.dispatch('app-user/getSelf')
      .then(response => {
        store
          .dispatch('menu-form/getForms', {
            search: searchQuery.value,
            perPage: perPage.value,
            page: currentPage.value,
            sortBy: sortBy.value,
            sortDesc: isSortDirDesc.value,
            role: roleFilter.value,
            plan: planFilter.value,
            status: statusFilter.value,
            user_id: response.data.id,
          })
          .then(resp => {
            const { data, total } = resp.data.data

            callback(data)
            totalForms.value = total
          })
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error fetching users list',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      })
  }

  const removeForm = userId => {
    return store.dispatch('menu-form/removeForm', { userId })
      .then(response => {
        this.$swal({
          icon: 'success',
          title: 'Deleted!',
          text: response.data.message,
        })
        refetchData()
      })
      .catch(error => {
        if (error.response !== undefined){
          this.$swal({
            icon: 'danger',
            title: 'Delete action failed!',
            text: error.response.data.message,
          })
        }
      })
  }

  return {
    getForms: getFormsWithReadWuthorization,
    removeForm,
    tableColumns,
    perPage,
    currentPage,
    totalForms,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refFormListTable,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  }
}
